import React from 'react'
import './kontakt_page.css';
import { Kaart } from '../../containers';

const emailAddress = "info@automaaler.ee";
const companyLocation = 'Automaaler, 26 Ringtee, Tartu, Estonia';
const openGoogleMaps = () => {
  const encodedLocation = encodeURIComponent(companyLocation);
  window.location.href = `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;
};

const sendEmail = () => {
  window.location.href = `mailto:${emailAddress}`;
};

const Kontakt_Page = () => {
  return (
    <div className="kontakt_page_bg">
      <Kaart />
      <div className='kontakt_page content_container'>
        <div className='aadress'>
          <div className='heading-column'>
          <p>Meie aadress</p>
          </div>
          <div className='content-column'>
            <div  className='google-maps'>
              <h1 onClick={openGoogleMaps}>Ringtee 26, Tartu</h1>
            </div>
          </div>
        </div>
        <div className="black-line"></div>
        <div className='kontakt-info'>
          <div className='heading-column'>
            <p>Võta ühendust</p>
          </div>
          <div className='content-column'>
            <h1>Kirjuta</h1>
            <div className='email-address'>
              <h1 onClick={sendEmail}><b>info@automaaler.ee</b></h1>
            </div>
          </div>
          <div className='content-column'>
            <h1>Helista</h1>
            <div className='telephone-number'>
              <a href="tel:+3725243455"><h1><b>52 434 55</b></h1></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Kontakt_Page