import React from 'react'
import './header.css';
import logo from '../../assets/AUTOMAALER_LOGO.png'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className="header" id="home">
        <div className='header-info'>
            <div className="header-title content_container">
                <h1 className="slide-in-right">Sinu auto parimates kätes</h1>
                <Link to="/kontakt"><button type="button" className='default-button jello-horizontal'>Võta ühendust</button></Link>
            </div> 
        </div>
        
    </div>
  )
}

export default Header