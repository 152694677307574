import React from 'react'
import './asendusauto_page.css';
import Asendusauto from "../../assets/automaaler-asendusautod.jpg";
import {Link} from "react-router-dom";

const Asendusauto_Page = () => {
  
  return (
    <div className="asendusauto-page">
      <div className="asendusauto-container content_container">
        <div className="asendusauto-item">
          <div className="asendusauto-content">
        <h2 className="asendusauto-title">Broneeri endale keretööde ajaks TASUTA ASENDUSAUTO</h2>
            <p className="asendusauto-text">
              Keretööde ajaks on meil võimalik Teile pakkuda <b>TASUTA</b> asendusautot, et Teil
              oleks võimalik ka remondiperioodil autoga sõitmist jätkata.<br/><br/>
              Asendusauto tuleb broneerida remondiaega kokku leppides. Meie asendusautodest
              on valikus manuaalkäigukastiga <b>Škoda Scala</b> või automaatkäigukastiga <b>Škoda Rapid</b>. Tee oma valik!
              Mõlemad sõidukid on bensiinimootoriga.
            </p>
          <Link to="/kontakt"><button type="button" className='default-button'>Võta ühendust</button></Link>
          </div>
        <img src={Asendusauto} alt="Asendusauto pilt" className="asendusauto-image" />
        </div>
      </div>
    </div>
  )
}

export default Asendusauto_Page