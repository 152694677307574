import './App.css';
import { Header, Teenused, Asendusauto, Kaart } from './containers';

function Home() {
  return (
      <>
        <Header />
        <Teenused />
        <Asendusauto />
        <Kaart />
      </>
  );
}

export default Home;
