import React from 'react'
import './partnerid_page.css';

const partnerData = [
  { logo: 'if.png', website: 'https://www.if.ee' },
  { logo: '3ta.png', website: 'https://www.bta.ee' },
  { logo: 'ergo.png', website: 'https://www.ergo.ee' },
  { logo: 'inges_uus.png', website: 'https://www.inges.ee' },
  { logo: 'lhv.png', website: 'https://www.lhv.ee/et/kindlustus' },
  { logo: 'pzu_uus.png', website: 'https://www.pzu.ee' },
  { logo: 'rsa_uus.png', website: 'https://www.rsagroup.com' },
  { logo: 'salva.png', website: 'https://www.salva.ee' },
  { logo: 'seesam_uus.png', website: 'https://www.seesam.ee' },
  { logo: 'swedbank.png', website: 'https://www.swedbank.ee/private/insurance/reports/start?language=EST' },
  { logo: 'debeer_uus.png', website: 'https://www.de-beer.com' },
  { logo: 'sikkens.png', website: 'https://www.sikkens.com' },
  { logo: 'mirka_uus.png', website: 'https://www.mirka.com' },
];

const Partnerid_Page = () => {
  return (
    <div className='partnerid'>
      <div className='partnerid-page content_container'>
        <h1 className='partnerid-title slide-in-right'>Meie partnerid</h1>
        <div className="partnerid-container">
          {partnerData.map((partner, index) => (
            <a
              key={index}
              href={partner.website}
              target="_blank"
              rel="noopener noreferrer"
              className="partner-link jello-horizontal"
            >
              <img
                src={require(`../../assets/partnerid_logod/${partner.logo}`)}
                alt={`Partner ${index + 1}`}
                className="partner-logo"
              />
            </a>
          ))}
        </div>
      </div>  
    </div>
  )
}

export default Partnerid_Page