import React, { useState,useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/AUTOMAALER_LOGO.png'
import './navbar.css';

// Using BEM -> Block Element Modifier (naming convention)

const Navbar = () => {

  const Menu = () => (
    <>
      <Link to="/teenused" onClick={closeMenu}><p>Teenused</p></Link>
      <Link to="/asendusauto" onClick={closeMenu}><p>Asendusauto</p></Link>
      <Link to="/galerii" onClick={closeMenu}><p>Galerii</p></Link>
      <Link to="/kontakt" onClick={closeMenu}><p>Kontakt</p></Link>
      <Link to="/partnerid" onClick={closeMenu}><p>Partnerid</p></Link>
    </>
  )

  const [toggleMenu, setToggleMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closeMenu = () => {
    setToggleMenu(false);
  };

  const toggleMenuHandler = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <div className='whole-navbar'>
      <div className="navbar content_container">
        <div className="navbar-links">
          <div className="navbar-links_logo">
            <Link to="/"><img src={logo} alt="logo" onClick={closeMenu}/></Link>
          </div>
          <div className="navbar-links_container">
            <Menu/>
          </div>
        </div>
        {<div className='navbar-menu'>
          {toggleMenu ? <RiCloseLine color="#fff" size={27} onClick={toggleMenuHandler}/>
          : <RiMenu3Line color="#fff" size={27} onClick={toggleMenuHandler}/>
          }
          {toggleMenu && (
            <div className="navbar-menu_container slide-in-left">
              <div className="navbar-menu_container-links">
                <Menu />
              </div>
            </div>
          )}
        </div>}
      </div>
    </div>
  )
}

export default Navbar