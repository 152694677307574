import { React } from 'react'

import { Footer } from './containers';
import { Navbar } from './components';
import { Teenused_Page, Asendusauto_Page, Galerii_Page, Kontakt_Page, Partnerid_Page } from './pages';	
import './App.css';
import Home from './Home';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Navbar />
        <ScrollToTop />
        <div className="App">
            <div className="gradient__bg">
              <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/teenused" exact element={<Teenused_Page />} />
                <Route path="/asendusauto" exact element={<Asendusauto_Page/>} />
                <Route path="/galerii" exact element={<Galerii_Page/>} />
                <Route path="/kontakt" exact element={<Kontakt_Page/>} />
                <Route path="/partnerid" exact element={<Partnerid_Page/>} />
              </Routes>
            </div>
            <Footer />
        </div>
    </Router>
  )
}

export default App