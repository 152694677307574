import React, { useEffect, useState } from 'react';
import './teenused_page.css';
import Kahjukasitlus from '../../assets/teenused_pildid/kontor.jpg'
import Keretood from '../../assets/teenused_pildid/kerevenitus.jpg'
import Varvimistood from '../../assets/teenused_pildid/kamber.jpg'
import Kalkulatsioonid from '../../assets/teenused_pildid/A4_katki.jpg'
import AvariilisteAutodeTaastamine from '../../assets/teenused_pildid/mini.jpg'

const ServiceItem = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="service-item">
      <h2 className="service-title" onClick={() => setIsOpen(!isOpen)}>
        {title}
      </h2>
      {isOpen && <p className="service-text">{description}</p>}
    </div>
  );
};

const Teenused_Page = () => {
  
  useEffect(() => {
    const serviceItems = document.querySelectorAll('.service-item');

    const handleScroll = () => {
      serviceItems.forEach((item) => {
        const itemTop = item.getBoundingClientRect().top;

        if (itemTop < window.innerHeight - 200) {
          item.classList.add('in-view');
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.65, // Adjust this value based on your needs
    });

    serviceItems.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  

  return (
    <div className="teenused-page">
      <div className="services-container content_container">

        <h1 className="services-title slide-in-right">Teenused</h1>

        <div className="service-item">
          <img src={Kahjukasitlus} alt="Service 1" className="service-image hide-mobile" />
          <div className="service-content">
            <h2 className="service-title service-content-right">Kahjukäsitlus</h2>
            <p className="service-text service-content-right">
              Kahjukäsitluse käigus hindame teie sõiduki kahjustusi, 
              määrame töö hinna ning otsime ja tellime vajalikud varuoasd. 
              Kahjukäsitleme ja remondime kõikide kindlustusseltside kahjusid.
            </p>
            <p className="service-text service-content-right">
              Sõiduki ettenäitamiseks ja hindamiseks palume võimalusel eelnevalt
              aeg kokku leppida, kas telefoni teel: <b>52 434 55</b> või e-maili teel: <b>info@automaaler.ee</b>
            </p>
          </div>
          <img src={Kahjukasitlus} alt="Service 1" className="service-image show-mobile" />
        </div>

        <div className="service-item service-item-right">
          <div className="service-content">
            <h2 className="service-title">Kalkulatsioonid</h2>
            <p className="service-text">
              Kui Teie sõidukiga on juhtunud õnnetus, siis oleme valmis aitama.
              Olgu tegu liiklus- või kaskokindlustusjuhtumiga, tuleb täita digitaalne
              kahjuteade <b><u><a href="https://www.lkf.ee/et/liiklus%C3%B5nnetus/juhtus-avarii">(juhtus avarii)</a></u></b> või võtta eraldi ühendust enda kindlustusandjaga,
              et kahjuavaldus saaks vormistatud.
              <br />
              Järgnevalt palume võtta meiega ühendust, et saaksime sõiduki üle vaadata ning koostada kalkulatsiooni.
            </p>
          </div>
          <img src={Kalkulatsioonid} alt="Service 2" className="service-image-right" />
        </div>

        <div className="service-item">
          <img src={Varvimistood} alt="Service 2" className="service-image hide-mobile" />
          <div className="service-content">
            <h2 className="service-title service-content-right">Värvimistööd</h2>
            <p className="service-text service-content-right">
              Värvimistöid teostame moodsas värvikambris FIRAT, kasutades kvaliteetseid värvimaterjale, 
              mis on toodetud tunnustatud värvifirmade poolt nagu Sikkens ja DeBeer. Värvime nii sõidukeid
              kui ka erilahendeid. Värvida saab kõike! :)
            </p>
          </div>
          <img src={Varvimistood} alt="Service 1" className="service-image show-mobile" />
        </div>

        <div className="service-item service-item-right">
          <div className="service-content">
            <h2 className="service-title">Keretööd</h2>
            <p className="service-text">
              Olgu teie sõiduk avariiline või vajab muul moel modifitseerimist,
              meie töötajad leiavad teie probleemile parima lahenduse. Teostame montaaži,
              kereosade vahetust, kerevenitus- ja keevitustöid.
            </p>
          </div>
          <img src={Keretood} alt="Service 2" className="service-image-right" />
        </div>

        <div className="service-item">
          <img src={AvariilisteAutodeTaastamine} alt="Service 2" className="service-image hide-mobile" />
          <div className="service-content">
            <h2 className="service-title service-content-right">Avariiliste autode taastamine</h2>
            <p className="service-text service-content-right">
                Teostame avariiliste autode taastamist nö võtmed kätte põhimõttel 
              (alates kahju hindamisest, suhtlemisest kindlustusseltsidega, varuosade tellimisest,
              kere- ja värvimistöödest kuni taastatud sõiduki üleandmiseni kliendile).                 
            </p>
          </div>
          <img src={AvariilisteAutodeTaastamine} alt="Service 1" className="service-image show-mobile" />
        </div>

      </div>
    </div>
  );
};

export default Teenused_Page;