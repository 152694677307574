import React from 'react'
import './teenused.css';
import { TfiLayoutLineSolid } from "react-icons/tfi";
import { Link } from 'react-router-dom';

const Teenused = () => {
  return (
      <div className="teenused">
        <div className='content_container'>
            <h1>Teenused</h1>
            <div className="teenused-container">
                <div className="teenused-column1">
                    <div className='teenused-object'>
                        <TfiLayoutLineSolid className="teenused-line" color='#fff' size={35}/><h3>Kahjukäsitlus</h3>
                    </div>
                    <div className='teenused-object'>
                        <TfiLayoutLineSolid className="teenused-line" color='#fff' size={35}/><h3>Kalkulatsioonid</h3>
                    </div>
                    <div className='teenused-object'>
                        <TfiLayoutLineSolid className="teenused-line" color='#fff' size={35}/><h3>Värvimistööd</h3>
                    </div>
                </div>    
                <div className="teenused-column2">
                    <div className='teenused-object'>
                        <TfiLayoutLineSolid className="teenused-line" color='#fff' size={35}/><h3>Keretööd</h3>
                    </div>
                    <div className='teenused-object'>
                        <TfiLayoutLineSolid className="teenused-line" color='#fff' size={35}/><h3>Avariilised autod</h3>
                    </div>
                    <Link to="/teenused"><button type="button" className='default-button'>Kõik teenused</button></Link>
                </div>  
            </div>
        </div>
    </div>
  )
}

export default Teenused