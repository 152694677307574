import React, { useState, useEffect } from 'react'
import './galerii_page.css';

const Galerii_Page = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Dynamically import images from the specified folder
    const importAll = (r) => r.keys().map(r);
    const imageContext = require.context('../../assets/galerii_pildid', false, /\.(png|jpe?g|svg)$/); // Update with your image folder path
    const imagePaths = importAll(imageContext);

    setImages(imagePaths);
  }, []); // Run this effect only once when the component mounts

  const openImage = (index) => {
    setSelectedImage(index);
    setIsModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeImage = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const nextImage = () => {
    setSelectedImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setSelectedImage((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className='gallery_bg'>
      <div className='content_container'>
        <div className='gallery-text slide-in-right'>
          <h1>Galerii</h1>
          <p>Valik Automaalri meeskonna poolt tehtud töödest</p>
        </div>
        <div className="gallery-container">
          {images.map((image, index) => (
            <div key={index} className="image-container" onClick={() => openImage(index)}>
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}

          {selectedImage !== null && (
            <div className="modal" onClick={closeImage}>
              <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="close-btn" onClick={closeImage}>&times;</span>
                <img src={images[selectedImage]} alt={`Image ${selectedImage + 1}`} />
                <span className="prev-btn" onClick={prevImage}>&lt;</span>
                <span className="next-btn" onClick={nextImage}>&gt;</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Galerii_Page;