import React from 'react'
import './footer.css';
import logo from '../../assets/AUTOMAALER_LOGO.png'
import { TfiLocationPin, TfiEmail } from "react-icons/tfi";
import { BsTelephone, BsClock  } from "react-icons/bs";
import { AiOutlineLike } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa6";

const emailAddress = "info@automaaler.ee";
const companyLocation = 'Automaaler, 26 Ringtee, Tartu, Estonia';
const openGoogleMaps = () => {
  const encodedLocation = encodeURIComponent(companyLocation);
  window.location.href = `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;
};


const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

const smoothScrollToTop = () => {
  const startPosition = window.pageYOffset;
  const targetPosition = 0;
  const distance = targetPosition - startPosition;
  const startTime = performance.now();
  const duration = 700; // 0.7 seconds

  const scrollStep = (timestamp) => {
    const currentTime = timestamp - startTime;
    const progress = Math.min(currentTime / duration, 1);
    const easedProgress = easeInOutQuad(progress);

    window.scrollTo(0, startPosition + distance * easedProgress);

    if (currentTime < duration) {
      requestAnimationFrame(scrollStep);
    }
  };

  requestAnimationFrame(scrollStep);
};

const sendEmail = () => {
  window.location.href = `mailto:${emailAddress}`;
};


const Footer = () => {
  return (
    <div className="footer gradient__bg">
      <div className="footer-container content_container">
        <div className="footer-column footer-logo">
          <img onClick={smoothScrollToTop} src={logo} alt='Logo' />
        </div>
        <div className="footer-column">
          <h1>Võta ühendust</h1>
          <div className='footer-object google-maps'>
            <TfiLocationPin className='footer-icon' color='#fff' size={35}/><p onClick={openGoogleMaps}>Ringtee 26 / 51013 Tartu</p>
          </div>
          <div className='footer-object email-address'>
            <TfiEmail className='footer-icon' color='#fff' size={35}/><p onClick={sendEmail}>{emailAddress}</p>
          </div>
          <div className='footer-object telephone-number'>
            <BsTelephone className='footer-icon' color='#fff' size={35}/><a href="tel:+3725243455"><p>52 434 55</p></a>
          </div>
          <div className='bottom-line'></div>
        </div>
        <div className="footer-column">
          <h1>Oleme avatud</h1>
          <div className='footer-object'>
            <BsClock className='footer-icon' color='#fff' size={35}/><p>Esmaspäev-Reede / 9.00-17.00</p>
          </div>
          <div className='bottom-line'></div>
        </div>
        <div className="footer-column">
          <h1>Jälgi meid</h1>
          <div className='footer-object'>
            <a href='https://www.facebook.com/profile.php?id=100057574002885'><FaFacebook className='footer-icon footer-facebook' id="footer-facebook" background-color='#0078B4' size={45}/></a>
          </div>
          <div className='bottom-line'></div>
        </div>
      </div>
    </div>
  )
}

export default Footer