import React from 'react'
import './asendusauto.css';
import asendusauto from '../../assets/automaaler-asendusautod_cropped.jpg'
import { Link } from 'react-router-dom';

const Asendusauto = () => {
  return (
    <div className="asendusauto">
        <div className="asendusauto-column asendusauto-column1">
            <div className="content_container">
                <h1>TASUTA asendusauto</h1>
                <p>Remonditööde ajaks anname teile kasutuseks TASUTA asendusauto, et Teil oleks võimalik ka remondiperioodil autoga sõitmist jätkata.</p>
                <Link to="/asendusauto"><button type="button" id="default-button" className='default-button jello-horizontal'>Uuri lähemalt</button></Link>
            </div>
        </div>
        <div className="asendusauto-column asendusauto-column2">
            <img src={asendusauto} alt="Asendusauto" />
        </div>
    </div>
  )
}

export default Asendusauto